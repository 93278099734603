import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "game",
        component: () => import("../views/Game.vue")
    }, {
        path: "/leaderboard",
        name: "leadboard",
        component: () => import("../views/LeaderBoard.vue")
    },]


const router = new VueRouter({
  
    base: "localhost:8080",
    routes
});

export default router;