import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.purple,
                secondary: '#ea9ab2',
                accent: colors.shades.black,
                error: colors.red.accent3,
                background: colors.red
            },
            dark: {
                primary: colors.blue.lighten3,
                secondary: '#ea9ab2',
                background: colors.indigo.base
            }
        }
    },
    iconfont: 'mdi'
});

export default vuetify;
