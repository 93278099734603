<template>
  <v-app>
    <v-main>
      <v-row class="text-center">
        <v-col class="ma-3">
          <v-icon left x-large @click="darkMode()" v-if="!$vuetify.theme.dark"
            >mdi-moon-waxing-crescent</v-icon
          >
          <v-icon v-else left x-large @click="darkMode()"
            >mdi-white-balance-sunny</v-icon
          >
          <v-icon right x-large @click="goToLeaderboard()" v-if="!onLeaderboard"
            >mdi-stairs-up</v-icon
          >
          <v-icon v-else right x-large @click="goToGame()"
            >mdi-controller-classic</v-icon
          >
        </v-col>
      </v-row>
    </v-main>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      onLeaderboard: false,
    };
  },
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    goToLeaderboard() {
      this.$router.push("/leaderBoard");
      this.onLeaderboard = true;
    },
    goToGame() {
      this.$router.push("/");
      this.onLeaderboard = false;
    },
  },
};
</script>
<style >
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.1s ease;
}

.fade-leave-active {
  transition: opacity 0.1s ease;
  opacity: 0;
}

body {
  touch-action: manipulation;
}

</style>
